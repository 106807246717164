import Vue from 'vue'
import { LiffListConfigInterface, TableDataType } from '@/components/list/types/list'
class listConfig {
  protected vm?: Vue
  protected listKey?: string

  get(vm: Vue, listKey: string) : LiffListConfigInterface {
    this.vm = vm
    this.listKey = listKey

    return {
      searchFilterOrder: 0,
      pageTitle: 'module.product',
      search: 'product.search_keyword',
      liffFilter: {
        status: {
          type: 'tab',
          create: () => true,
          options: [
            {text: '全部', value: 'null'},
            {text: vm.$t('action.up'), value: 'true'},
            {text: vm.$t('action.downed'), value: 'false'}
          ],
          metaTab: () => import('modules/product/components/productSearchSwitchTab.vue')
        },
      },
      // selectedData: row => ({
      //   id: row.id,
      //   name: row.name,
      //   title: row.title,
      // }),
      sort: [
        { label: 'data.created_at', key: 'created_at' },
      ],
      // metaAction: {
      //   create: {
      //     label: 'action.create',
      //     linkTarget: '_self',
      //     type: 'route',
      //     route: () => ({ name: 'product-create' }),
      //   },
      // },
      listItem: () => import('@/modules/product/components/productListItem.vue'),
      hideSearchInput: true,
      itemCols: {
        cols: 6,
        sm: 6,
        md: 4,
        lg: 4,
        xl: 3,
      },
      multipleDisplayCardMode: true,
      batch: {
        batch_active: {
          label: '批次上架',
          column: 'status',
          value: true,
        },
        batch_inactive: {
          label: '批次下架',
          column: 'status',
          value: false,
        },
      },
      dataAction: {
        update: {
          label: 'action.edit',
          route: row => ({ name: 'product-update', params: { target: row.id } }),
          linkTarget: '_self',
          color: 'primary'
        },
        down: {
          label: 'action.down',
          create: (row) => {
            return row.status
          },
          color: 'red',
          callback: (row, listActions) => {
            //@ts-ignore
            vm.patchApi(false, row, {'key': 'status'})
            listActions.index()
          },
        },
        up: {
          label: 'action.up',
          create: (row) => {
            return !row.status
          },
          callback: (row, listActions) => {
            //@ts-ignore
            vm.patchApi(true, row, {'key': 'status'})
            listActions.index()
          },
        },
        delete: {
          removeTarget: row => row.name,
        },
      },
    }

  }
}

export default new listConfig()
